<template>
  <div>
    <b-collapse :open="false" v-if="!sent">
      <template #trigger>
        <b-button
          :label="isBugsSelected ? 'Add a Bug' : 'Add topic'"
          icon-left="plus"
          type="is-info" />
      </template>
      <div class="notification">
        <div>
          <h4 class="title is-4">{{ isBugsSelected ? 'Bug details' : 'Topic details' }}</h4>

          <b-notification
            v-if="errors.length"
            type="is-danger"
            aria-close-label="Close"
            role="alert"
          >
            <div v-for="(error, index) in errors" :key="index">{{ error }}</div>
          </b-notification>

          <form @submit="handleSubmit">
            <b-field :label="isBugsSelected ? 'Title (Bug in a few words)' : 'Topic Title'">
              <b-input v-model="formData.title"></b-input>
            </b-field>

            <b-field :label="isBugsSelected ? 'Description of the bug' : 'Share your thoughts'">
              <editor
                v-model="formData.text"
                api-key="llkyn54j9cm6dvjfak7eywf59bxnopcwyoq8fetp590v7ftf"
                :init="{
                     height: 400,
                     menubar: false,
                     plugins: [
                       'advlist autolink lists link searchreplace visualblocks table paste code codesample help preview'
                     ],
                     toolbar:
                       'preview | undo redo | formatselect | bold italic backcolor | cocdesample | \
                       alignleft aligncenter alignright alignjustify | \
                       bullist numlist outdent indent | removeformat | help',
                     extended_valid_elements: 'a[href|target=_blank]'
                   }"
              />
            </b-field>

            <b-field label="Attachments">
              <b-upload
                v-model="dropFiles"
                accept="image/*"
                multiple
                drag-drop
                expanded
              >
                <section class="section">
                  <div class="content has-text-centered">
                    <p>
                      <b-icon icon="upload" size="is-large"></b-icon>
                    </p>
                    <p>Drop your files here or click to upload</p>
                  </div>
                </section>
              </b-upload>
            </b-field>

            <div class="tags">
              <span
                v-for="(file, index) in dropFiles"
                :key="index"
                class="tag is-primary"
              >
                {{file.name}}
                <button class="delete is-small" type="button" @click="deleteDropFile(index)"></button>
              </span>
            </div>

            <b-field>
              <b-checkbox v-model="formData.subscribe">Get notifications by email for new comments on that topic</b-checkbox>
            </b-field>

            <b-button type="is-success" native-type="submit">Submit</b-button>
          </form>

          <b-loading :is-full-page="false" v-model="loadingForm"></b-loading>
        </div>
      </div>
    </b-collapse>
    <div v-else class="notification has-text-centered is-success">
      <div v-if="isBugsSelected">
        <h3 class="title is-3">Thank you!</h3>
        <p>We really appreciate your support. We'll try to look into the problem and will post back our findings as soon as possible.</p>
      </div>
      <div v-else>
        <h3 class="title is-3">Awesome!</h3>
        <p>We really appreciate your interest in Odd Giants. Hope this will become a good discussion.</p>
      </div>
      <p class="has-text-centered has-text-weight-bold mt-4">If you like what we're doing and want to support us, so we can keep moving, you can become our patron on <a href="https://www.patreon.com/oddgiants" target="_blank">PATREON</a></p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Editor from '@tinymce/tinymce-vue';
import { Forum } from '@/services/api';

export default {
  name: 'topic-form',
  components: { Editor },
  data() {
    return {
      errors: [],
      dropFiles: [],
      formData: {
        subscribe: true,
        title: null,
        text: null,
      },
      loadingForm: false,
      sent: false,
    };
  },
  computed: {
    isBugsSelected() {
      return this.selectedForum.slug === 'bugs';
    },
    ...mapGetters('user', ['isAuthenticated']),
    ...mapState('forum', ['selectedForum', 'selectedTopic']),
  },
  methods: {
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
    showLogin() {
      this.$store.dispatch('global/toggleLoginModal', true);
    },
    handleSubmit(event) {
      event.preventDefault();

      this.errors = [];
      this.loadingForm = true;

      Forum.createTopic(
        this.selectedForum.slug,
        this.formData.title,
        this.formData.text,
        this.formData.subscribe,
        this.dropFiles,
      )
        .then(() => {
          this.sent = true;
          this.$store.dispatch('forum/loadTopics', 1);
        })
        .catch((error) => {
          const responseError = error.response.data;

          // Validation errors
          if (responseError.errors) {
            Object.keys(responseError.errors).forEach((key) => {
              this.errors.push(responseError.errors[key][0]);
            });

            // General error
          } else {
            this.errors.push('Sorry :( Something went wrong... please try again later');
          }
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>

<template>
  <section class="section">
    <b-loading :is-full-page="false" v-model="loadingForum"></b-loading>

    <div v-if="selectedForum">
      <h1 class="title has-text-centered">
        {{ selectedForum.title }}
      </h1>

      <div class="section">{{ selectedForum.description }}</div>

      <topic-form></topic-form>

      <topic-list :topics="topics.data"></topic-list>

      <b-pagination
        class="mt-4"
        v-model="topics.current_page"
        :total="topics.total"
        :per-page="topics.per_page"
        range-before="3"
        range-after="3"
        order="is-centered"
        icon-pack="fas"
        @change="handlePageChange"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page">
      </b-pagination>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import TopicForm from '@/components/Forum/TopicForm.vue';
import TopicList from '@/components/Forum/TopicList.vue';

export default {
  name: 'ForumDetails',
  components: { TopicList, TopicForm },
  props: {
    forum: String,
  },
  computed: {
    isAnnouncements() {
      return this.selectedForum.slug === 'announcements';
    },
    ...mapState('forum', ['loadingForum', 'selectedForum', 'topics']),
  },
  mounted() {
    this.loadForum(this.forum);
  },
  beforeRouteUpdate(to, from, next) {
    this.loadForum(to.params.forum);
    next();
  },
  methods: {
    async loadForum(forumId) {
      await this.$store.dispatch('forum/loadForum', forumId);
      this.$store.dispatch('forum/loadTopics', 1);
    },
    handlePageChange(pageNum) {
      this.$store.dispatch('forum/loadTopics', pageNum);
    },
  },
};
</script>

<style scoped lang="scss">

</style>
